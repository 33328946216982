import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import '../Styles/Papers.css'

export default function Papers() {
    return (
        <Grid container>
            <Grid item sm={0} md={2}>
            </Grid>
            <Grid item sm={12} md={8}>
                <Typography sx={{fontWeight: 'bold'}} gutterBottom variant="h5" component="div">
                    Paper Presentations
                </Typography>

                <Typography variant="body1">
                    Five paper presentations will be accepted for a morning session on Sunday, April 7. Presenters will present a 20-minute presentation with 5 minutes for questions. There are no restrictions on the topics that may be covered, although the Historic Brass Society mission statement notes that "the history, music, literature and performance practice of early brass instruments such as natural trumpet, natural horn, early trombone, cornetto, serpent, keyed bugle, keyed trumpet, early valve horn, 19th century brass instruments are some of the main issues of concern to the HBS." Paper presenters shall receive no remuneration and will be responsible for their own expenses.
                </Typography>

                <br/>

                <Typography variant="body1">
                    Proposals must be submitted by current Historic Brass Society (HBS) members (ensure that membership is for 2024 or extending into 2024) – membership information can be found at <a href={'https://www.historicbrass.org'}>www.historicbrass.org</a>.  Proposals submitted by non-HBS members will not be considered by the screening committee.
                </Typography>

                <Typography variant="body1">
                    To apply, please complete the following application form by December 15, 2023&nbsp;
                     <a href={"https://docs.google.com/forms/d/e/1FAIpQLSc8zkfwItONoIJwXIaupx6hHLvwbDt1tPStbMty57W02-ru1g/viewform?usp=sf_link"}>North American Baroque Trumpet Competition and Conference Presentation Application</a>.
                    The screening committee will notify accepted presenters by January 5, 2024.
                </Typography>

                <br/>

                <Typography variant="body1">
                    The paper presentations is being organized by Scott Muntefering.
                    <br/>
                    <br/>
                    <img className={'scott-image'} alt={'scott munterfering'} src={'/Images/Artists/ScottMuntefering.png'}/>
                </Typography>

            </Grid>
            <Grid item sm={0} md={2}>
            </Grid>
        </Grid>
    )
}