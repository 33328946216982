import './Styles/App.css';
import {
    createBrowserRouter, RouterProvider
} from "react-router-dom";
import Root from "./Root";
import Home from "./Pages/Home";
import ErrorPage from "./Pages/ErrorPage";
import Artists from "./Pages/Artists";
import Artist, {loader} from "./Components/Artist";
import CompetitionInfo from "./Pages/CompetitionInfo";
import Papers from "./Pages/Papers";
import CSU from "./Pages/CSU";
import {Schedule} from "./Pages/Schedule";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: "/",
                element: <Home/>,
            },
            {
                path: "/Schedule",
                element: <Schedule/>,
            },
            {
                path: "/Artists",
                element: <Artists/>,
            },
            {
                path: "/Artist/:ArtistName",
                element: <Artist/>,
                loader: loader
            },
            {
                path: "/CompetitionInfo",
                element: <CompetitionInfo/>
            },
            {
                path: "/Papers",
                element: <Papers/>
            },
            {
                path: "/CSU",
                element: <CSU/>
            }
        ]
    }
]);

function App() {
  return (
      <RouterProvider router={router} />
  );
}

export default App;
